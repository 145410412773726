import React, {useEffect} from 'react'

const DEMO_PAGE_URL = 'https://insights.opentrons.com/schedule-demo'

const DemoPage = () => {
  useEffect(() => {
    window.location.replace(DEMO_PAGE_URL)
  }, [])

  return <></>
}

export default DemoPage
